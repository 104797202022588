/* Start Listing Horizontal */
.ListingHorizontalCon1 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 3px;
  min-width: 320px;
  width: 100%;
  max-width: 500px;
  max-height: 130px;
  overflow: hidden;
  position: relative;
  box-shadow: var(--app-shadow-horizontal-listings);
}
.ListingHorizontalCon2 {
  width: 120px;
  flex-shrink: 0;
  align-self: stretch;
  background: lightgray 50% / cover no-repeat;
}
.ListingHorizontalCon3 {
  display: flex;
  width: 210px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;

  /* border-radius: 0px 5px 5px 0px; */
  background: var(--Item-backg-secondary, #e8e8e8);
}
.ListingHorizontalCon4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
}
.ListingHorizontalCon5 {
  display: flex;
  padding: 2px 6px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;

  background: var(--Item-backg-primary, #460e00);
}
.ListingHorizontalCon6 {
  display: flex;
  padding: 0px 6px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
}
.ListingHorizontalCon7 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}
.ListingHorizontalCon8 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  flex: 1 0 0;
}
.ListingHorizontalCon9 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.ListingHorizontalCon10 {
  display: flex;
  padding: 0px 2px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 0px 3px;
  background: var(--Item-true-main, #ff3c00);
}
.ListingHorizontalCon11 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2px;
}
.ListingHorizontalCon12 {
  display: flex;
  height: 32px;
  padding: 6px 12px;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
}
.ListingHorizontalCon13 {
  display: flex;
  align-items: center;
  gap: 12px;
}
.ListingHorizontalCon14 {
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 27px;
  background: var(--Item-backg-primary, #460e00);
  box-shadow: 0px 2px 4px 1px #fff;
}
.ListingHorizontalCon15 {
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.ListingHorizontalCon16 {
  display: flex;
  align-items: center;
  gap: 2px;
}
/* End Listing Horizontal */
/* Start Listing Virtical */
.ListingVeriticalCon1 {
  display: flex;
  /* height: 207px; */
  min-width: 160px;
  max-width: 200px;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
  box-shadow: var(--app-shadow-virtical-listings);
}

.ListingActiveAndLastOpend::before {
  content: ""; /* Creates an empty element */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.35); /* Blueish overlay */
  pointer-events: none; /* Ensures it doesn't interfere with clicks */
  border-radius: 5px; /* Match the parent element's border radius */
}
.ListingVeriticalCon2 {
  height: 98px;
  flex-shrink: 0;
  align-self: stretch;
  background: lightgray 50% / cover no-repeat;
}
.ListingVeriticalCon3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;

  /* border-radius: 5px; */
  /* border-radius: 0px 0px 5px 5px; */
  /* border: 0.5px solid var(--Item-backg-primary, #460e00); */
  background: var(--Item-backg-secondary, #e8e8e8);
}
.ListingVeriticalCon4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
}
.ListingVeriticalCon5 {
  display: flex;
  padding: 2px 6px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;

  /* border-radius: 0px 0px 2px 2px; */
  background: var(--Item-backg-primary, #460e00);
}
.ListingVeriticalCon6 {
  display: flex;
  padding: 0px 6px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
}
.ListingVeriticalCon7 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}
.ListingVeriticalCon8 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  flex: 1 0 0;
}
.ListingVeriticalCon9 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.ListingVeriticalCon10 {
  display: flex;
  padding: 0px 2px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 0px 3px;
  background: var(--Item-true-main, #ff3c00);
}
.ListingVeriticalCon11 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2px;
}
.ListingVeriticalCon12 {
  display: flex;
  padding: 6px 12px;
  justify-content: space-between;
  align-items: flex-end;
  flex: 1 0 0;
  align-self: stretch;
  box-sizing: border-box;
}
.ListingVeriticalCon13 {
  display: flex;
  align-items: center;
  gap: 12px;
}
.ListingVeriticalCon14 {
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 27px;
  background: var(--Item-backg-primary, #460e00);
  box-shadow: 0px 2px 4px 1px #fff;
}
.ListingVeriticalCon15 {
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 27px;
  background: var(--Item-backg-primary, #460e00);
  box-shadow: 0px 2px 4px 1px #fff;
}
.ListingVeriticalCon16 {
  display: flex;
  align-items: center;
  gap: 2px;
}

.ListingVeriticalTitleMini {
  color: var(--Item-text-primary, #ffa500);
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.ListingVeriticalTitle {
  color: var(--Item-text-primary, #ffa500);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.ListingVeriticalOnDiscount {
  color: var(--Item-text-highlight, #ffa500);
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ListingVeriticalPriceMini {
  color: var(--Item-text-primary, #ffa500);
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.ListingVeriticalPrice {
  color: var(--Item-text-primary, #ffa500);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.ListingVeriticalConditionMini {
  color: var(--Item-text-primary, #ffa500);
  font-family: Inter;
  font-size: 6px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.ListingVeriticalCondition {
  color: var(--Item-text-primary, #ffa500);
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.ListingVeriticalPriceBeforeText {
  color: var(--Item-text-secondary, #6d5300);
  font-family: Inter;
  font-size: 6px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ListingVeriticalPriceBeforePrice {
  color: var(--Item-text-secondary, #6d5300);
  font-family: Inter;
  font-size: 6px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ListingVeriticalDescription {
  align-self: stretch;
  color: var(--Item-text-secondary, #6d5300);
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.08px;
}
/* End Listing Virtical */
/* Start Listing Virtical Mini */
.ListingMiniVeriticalCon1 {
  display: flex;
  min-width: 112px;
  max-width: 112px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: var(--app-shadow-mini-listings);
  position: relative;
  overflow: hidden;
}
.ListingMiniVeriticalCon2 {
  height: 64px;
  align-self: stretch;
  background: lightgray 50% / cover no-repeat;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(0, 0, 0, 0) 16%
    ),
    linear-gradient(180deg, rgba(0, 0, 0, 0) 79%, rgba(0, 0, 0, 0.8) 100%);
}
.ListingMiniVeriticalCon2Image {
  height: 60px;
  width: 100%;
  object-fit: cover;
  mix-blend-mode: multiply;
}
.ListingHorizontalCon2Image {
  width: 120px;
  height: 100%;
  /* max-height: 120px; */
  object-fit: cover;
  mix-blend-mode: multiply;
}
.ListingVirticalCon2Image {
  height: 100px;
  width: 100%;
  object-fit: cover;
  mix-blend-mode: multiply;
}
.ListingMiniVeriticalCon3 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  /* border-radius: 0px 0px 5px 5px; */
  /* border: 0.5px solid var(--Item-backg-primary, #460e00); */
  background: var(--Item-backg-secondary, #e8e8e8);
  box-sizing: border-box;
}
.ListingMiniVeriticalCon3B {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;

  min-width: 100px;
  max-width: 100px;
  border-radius: 6px;
  overflow: hidden;
  /* border-radius: 0px 0px 5px 5px; */
  /* border: 0.5px solid var(--Item-backg-primary, #460e00); */
  background: var(--Item-backg-secondary, #e8e8e8);
  box-sizing: border-box;
}
.ListingMiniVeriticalCon4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
}
.ListingMiniVeriticalCon5 {
  display: flex;
  padding: 2px 6px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;

  /* border-radius: 0px 0px 2px 2px; */
  background: var(--Item-backg-primary, #460e00);
}
.ListingMiniVeriticalCon6 {
  display: flex;
  padding: 0px 6px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}
.ListingMiniVeriticalCon7 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}
.ListingMiniVeriticalCon8 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  flex: 1 0 0;
}
.ListingMiniVeriticalCon9 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.ListingMiniVeriticalCon10 {
  display: flex;
  padding: 0px 2px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 0px 3px;
  background: var(--Item-true-main, #ff3c00);
}
.ListingMiniVeriticalCon11 {
  display: flex;
  padding: 1px 3px;
  align-items: flex-end;
  gap: 3px;
  align-self: stretch;
}
.ListingMiniVeriticalCon12 {
  display: flex;
  align-items: center;
  gap: 2px;
}
/* End Listing Virtical Mini */
/* Start Listing Business Pack */

.BusinessesPackOfPacksDisplayCon1 {
  display: flex;
  padding: 0px 0px 0px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}
.BusinessesPackDisplayCon1 {
  display: flex;
  width: 100%;
  min-height: 230px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}
.BusinessesPackDisplayCon2 {
  display: flex;
  justify-content: space-between;
  padding: 0px 12px;
  align-items: center;
  align-self: stretch;
}
.BusinessesPackDisplayTitle {
  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.2px;
}
.BusinessesPackDisplayVerTodo {
  color: var(--app-green, #3a7d44);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.12px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
}
.BusinessesPackDisplayCon3 {
  display: flex;
  /* height: 233px; */
  padding: 6px 6px;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-padding-left: 20px;
  scroll-padding-right: 20px;
  scroll-snap-type: x mandatory;
}

.BusinessesPackDisplayCon3 > * {
  scroll-snap-align: start; /* Adjust alignment as needed (start, center, or end) */
}

/* End Listing Business Pack */
/* Start Listing Business in search */
.BusinessInSearchCon1 {
  display: flex;
  min-width: 314px;
  width: 100%;
  max-width: 500px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  position: relative;
  border-radius: 5px;
  box-shadow: var(--app-shadow-businesses-search);
  overflow: hidden;
}

.BusinessInSearchCon1BackgroundImg {
  /* position: absolute; */
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container without distortion */
  mix-blend-mode: multiply;
  z-index: 0;
}
.BusinessInSearchCon2 {
  /* display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: lightgray 50% / cover no-repeat; */
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  /* z-index: 1; */
}
.BusinessInSearchCon3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0) 74.5%
    ),
    linear-gradient(179deg, rgba(0, 0, 0, 0) 1.15%, rgba(0, 0, 0, 0.7) 99.49%);
  z-index: 0;
}
.BusinessInSearchCon4 {
  display: flex;
  padding: 6px 6px;
  box-sizing: border-box;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
  flex: 1 0 0;
  align-self: stretch;
}
.BusinessInSearchCon5 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
}
.BusinessInSearchCon6 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
.BusinessInSearchBusinessName {
  align-self: stretch;

  color: var(--Header-primary, #ff3c00);

  /* text shadow 1 */
  text-shadow: 1px 1.5px 4px rgba(0, 0, 0, 0.9);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.16px;
}
.BusinessInSearchBusinessShortDescription {
  align-self: stretch;
  color: var(--Header-primary, #ff3c00);

  /* text shadow 1 */
  text-shadow: 1px 1.5px 4px rgba(0, 0, 0, 0.9);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;
}
.BusinessInSearchCon7 {
  display: flex;
  padding: 0px 6px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
.BusinessInSearchCon8 {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 25%;
  border: 2px solid #888;
  background: lightgray 50% / cover no-repeat;
}
.BusinessInSearchCon9 {
  display: flex;
  position: relative;
  padding: 0px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 0px 0px 5px 5px;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.5) inset;

  /* border-radius: 0px 0px 12px 12px; */
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  border-left: 1px solid #000;
  /* background: #ff6f433e; */
  background: #ffffff;
  /* z-index: 2; */
}

.BusinessInSearchCon9B {
  background: linear-gradient(141deg, #f4c542 50.91%, #3a7d44 50.91%);
}
.BusinessInSearchCon10 {
  display: flex;
  /* height: 100%; */
  padding: 6px 6px;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
  scroll-snap-type: x mandatory;
  scroll-padding-left: 12px;
}

.BusinessInSearchCon10 > * {
  scroll-snap-align: start; /* Adjust alignment as needed (start, center, or end) */
}
/* End Listing Business in search */
/* Start Listing Pack display */
.ListingPackDisplayCon1 {
  display: flex;
  width: 100%;
  padding: 0px 0px;
  min-height: 160px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}
.ListingPackDisplayCon2 {
  display: flex;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 12px;
  align-items: center;
  align-self: stretch;
}

.ListingPackDisplayCon3 {
  display: flex;
  padding: 6px 6px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scroll-padding-left: 16px;
}

.ListingPackDisplayCon3 > * {
  scroll-snap-align: start; /* Adjust alignment as needed (start, center, or end) */
}
/* End Listing Pack display */
.RatingStarCon1 {
  display: inline-flex;
  justify-content: center;
  align-items: flex-end;
  gap: 6px;
}
.RatingStarCon2 {
  /* display: flex;
  align-items: center;
  gap: 4px; */

  display: flex;
  padding: 2px 3px 2px 2px;
  justify-content: center;
  align-items: center;
  gap: 3px;
  border-radius: 12px;
  background: var(
    --Mercado-MAI-pro,
    linear-gradient(0deg, #f80 0%, #e2ff04 100%)
  );
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 0px 6px 0px rgba(0, 0, 0, 0.25) inset;
}
.RatingStarRatedAtNumber {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.RatingStarCon3 {
  display: flex;
  align-items: center;
  gap: 2px;
}
.RatingStarReviewText {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* Start No Listings Message */
.NoListingsMiniCon1 {
  width: 100%;
  min-width: 100px;
  max-width: 140px;
  height: 151px;
  border-radius: 5px;
  overflow: hidden;
  border: 1px #3a7d44 solid;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: inline-flex;
}

.NoListingsMiniCon2 {
  align-self: stretch;
  height: 18px;
  padding-top: 3px;
  padding-bottom: 3px;
  background: #3a7d44;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  display: flex;
}

.NoListingsMiniTitle {
  color: white;
  font-size: 10px;
  font-family: Inter;
  font-weight: 500;
  word-wrap: break-word;
}

.NoListingsMiniCon3 {
  align-self: stretch;
  flex: 1 1 0;
  padding-left: 6px;
  padding-right: 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  background: var(--app-beige);
}

.NoListingsMiniMessage {
  flex: 1 1 0;
  text-align: center;
  color: #3a7d44;
  font-size: 12px;
  font-family: Inter;
  font-weight: 500;
  word-wrap: break-word;
}
/* End No Listings Message */
.ListingDisplayDateCon {
  display: flex;
  padding: 2px 0px;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
}
.ListingDisplayDateTextMini {
  flex: 1 0 0;
  color: #6d5300;
  font-family: Inter;
  font-size: 6px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.ListingDisplayDateText {
  flex: 1 0 0;
  color: #6d5300;
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.ListingDisplayViewsText {
  flex: 1 0 0;
  color: #6d5300;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* Display big listings start here */

/* .ListingVeriticalBigCon1R {
  display: flex;
  min-width: 200px;
  width: 100%;
  max-width: 500px;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
  box-shadow: 2px 4px 6px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  position: relative;
} */

.ListingVeriticalBigCon1 {
  display: flex;
  flex: 1 1 250px;
  max-width: 500px;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  position: relative;
}

/* .ListingVeriticalBigCon2 {
  height: 148px;
  align-self: stretch;
} */
.ListingVirticalBigCon2Image {
  height: 180px;
  width: 100%;
  object-fit: cover;
  mix-blend-mode: multiply;
}
.ListingVeriticalBigCon3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
  background: #fff;
}
.ListingVeriticalBigCon4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
.ListingVeriticalBigCon5 {
  display: flex;
  padding: 4px 6px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  background: var(--app-green, #3a7d44);
}
.ListingVeriticalBigTitle {
  color: var(--app-white, #fff);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.ListingVeriticalBigCon6 {
  display: flex;
  padding: 0px 6px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
.ListingVeriticalBigCon7 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}
.ListingVeriticalBigCon8 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
}
.ListingVeriticalBigCon9 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}
.ListingVeriticalBigCon10 {
  display: flex;
  padding: 2px 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 0px 3px;
  background: var(
    --liniar-for-logo,
    linear-gradient(0deg, #3a7d44 0%, #69e37b 100%)
  );
}
.ListingVeriticalBigOnDiscount {
  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ListingVeriticalBigPrice {
  color: var(--app-orange, #ff7043);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.ListingVeriticalBigCondition {
  color: var(--app-orange, #ff7043);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ListingVeriticalBigCon11 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2px;
}
.ListingVeriticalBigPriceBeforeText {
  color: var(--app-green, #3a7d44);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ListingVeriticalBigPriceBeforePrice {
  color: var(--app-green, #3a7d44);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ListingVeriticalBigDescription {
  align-self: stretch;
  color: var(--app-gray-medium, #6d6d6d);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.12px;
}
.ListingMiniVeriticalBigCon11 {
  display: flex;
  padding: 3px 6px;
  align-items: flex-end;
  gap: 3px;
  align-self: stretch;
}
.ListingDisplayBigDateCon {
  display: flex;
  padding: 2px 0px;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 50px;

  /* round button white shadow */
  box-shadow: 0px 2px 4px 1px rgba(255, 255, 255, 0.5);
}
.ListingDisplayBigDateText {
  flex: 1 0 0;
  color: var(--app-gray-medium, #6d6d6d);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.ListingDisplayBigViewsText {
  flex: 1 0 0;
  color: var(--app-gray-medium, #6d6d6d);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.ListingMiniVeriticalBigCon12 {
  display: flex;
  align-items: center;
  gap: 2px;
}

.ListingVeriticalBigCon12 {
  display: flex;
  padding: 3px 6px;
  align-items: flex-end;
  gap: 3px;
  align-self: stretch;
}
