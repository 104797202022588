.ListingHorizontalCon1 {
  width: 100%;
  min-width: 320px;
  max-width: 500px;
  max-height: 130px;
  box-shadow: var(--app-shadow-horizontal-listings);
  border-radius: 3px;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
  position: relative;
  overflow: hidden;
}

.ListingHorizontalCon2 {
  background: #d3d3d3 50% / cover no-repeat;
  flex-shrink: 0;
  align-self: stretch;
  width: 120px;
}

.ListingHorizontalCon3 {
  background: var(--Item-backg-secondary, #e8e8e8);
  flex-direction: column;
  flex: 1 0 0;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  width: 210px;
  display: flex;
}

.ListingHorizontalCon4 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  display: flex;
}

.ListingHorizontalCon5 {
  background: var(--Item-backg-primary, #460e00);
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  padding: 2px 6px;
  display: flex;
}

.ListingHorizontalCon6 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  padding: 0 6px;
  display: flex;
}

.ListingHorizontalCon7 {
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
}

.ListingHorizontalCon8 {
  flex-direction: column;
  flex: 1 0 0;
  align-items: flex-start;
  gap: 2px;
  display: flex;
}

.ListingHorizontalCon9 {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.ListingHorizontalCon10 {
  background: var(--Item-true-main, #ff3c00);
  border-radius: 0 3px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0 2px;
  display: flex;
}

.ListingHorizontalCon11 {
  flex-direction: column;
  align-items: flex-end;
  gap: 2px;
  display: flex;
}

.ListingHorizontalCon12 {
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-end;
  height: 32px;
  padding: 6px 12px;
  display: flex;
}

.ListingHorizontalCon13 {
  align-items: center;
  gap: 12px;
  display: flex;
}

.ListingHorizontalCon14 {
  background: var(--Item-backg-primary, #460e00);
  border-radius: 27px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 4px;
  display: flex;
  box-shadow: 0 2px 4px 1px #fff;
}

.ListingHorizontalCon15 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 4px;
  display: flex;
}

.ListingHorizontalCon16 {
  align-items: center;
  gap: 2px;
  display: flex;
}

.ListingVeriticalCon1 {
  min-width: 160px;
  max-width: 200px;
  box-shadow: var(--app-shadow-virtical-listings);
  border-radius: 3px;
  flex-direction: column;
  flex: 1 0 0;
  align-self: stretch;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.ListingActiveAndLastOpend:before {
  content: "";
  pointer-events: none;
  background-color: #ffffff59;
  border-radius: 5px;
  position: absolute;
  inset: 0;
}

.ListingVeriticalCon2 {
  background: #d3d3d3 50% / cover no-repeat;
  flex-shrink: 0;
  align-self: stretch;
  height: 98px;
}

.ListingVeriticalCon3 {
  background: var(--Item-backg-secondary, #e8e8e8);
  flex-direction: column;
  flex: 1 0 0;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
}

.ListingVeriticalCon4 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  display: flex;
}

.ListingVeriticalCon5 {
  background: var(--Item-backg-primary, #460e00);
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  padding: 2px 6px;
  display: flex;
}

.ListingVeriticalCon6 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  padding: 0 6px;
  display: flex;
}

.ListingVeriticalCon7 {
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
}

.ListingVeriticalCon8 {
  flex-direction: column;
  flex: 1 0 0;
  align-items: flex-start;
  gap: 2px;
  display: flex;
}

.ListingVeriticalCon9 {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.ListingVeriticalCon10 {
  background: var(--Item-true-main, #ff3c00);
  border-radius: 0 3px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0 2px;
  display: flex;
}

.ListingVeriticalCon11 {
  flex-direction: column;
  align-items: flex-end;
  gap: 2px;
  display: flex;
}

.ListingVeriticalCon12 {
  box-sizing: border-box;
  flex: 1 0 0;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-end;
  padding: 6px 12px;
  display: flex;
}

.ListingVeriticalCon13 {
  align-items: center;
  gap: 12px;
  display: flex;
}

.ListingVeriticalCon14, .ListingVeriticalCon15 {
  background: var(--Item-backg-primary, #460e00);
  border-radius: 27px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 4px;
  display: flex;
  box-shadow: 0 2px 4px 1px #fff;
}

.ListingVeriticalCon16 {
  align-items: center;
  gap: 2px;
  display: flex;
}

.ListingVeriticalTitleMini {
  color: var(--Item-text-primary, orange);
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ListingVeriticalTitle {
  color: var(--Item-text-primary, orange);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ListingVeriticalOnDiscount {
  color: var(--Item-text-highlight, orange);
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ListingVeriticalPriceMini {
  color: var(--Item-text-primary, orange);
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ListingVeriticalPrice {
  color: var(--Item-text-primary, orange);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ListingVeriticalConditionMini {
  color: var(--Item-text-primary, orange);
  font-family: Inter;
  font-size: 6px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.ListingVeriticalCondition {
  color: var(--Item-text-primary, orange);
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.ListingVeriticalPriceBeforeText, .ListingVeriticalPriceBeforePrice {
  color: var(--Item-text-secondary, #6d5300);
  font-family: Inter;
  font-size: 6px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ListingVeriticalDescription {
  color: var(--Item-text-secondary, #6d5300);
  letter-spacing: .08px;
  align-self: stretch;
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ListingMiniVeriticalCon1 {
  min-width: 112px;
  max-width: 112px;
  box-shadow: var(--app-shadow-mini-listings);
  background: #fff;
  border-radius: 5px;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.ListingMiniVeriticalCon2 {
  background: linear-gradient(#ffffff80 0%, #0000 16%), linear-gradient(#0000 79%, #000c 100%);
  align-self: stretch;
  height: 64px;
}

.ListingMiniVeriticalCon2Image {
  object-fit: cover;
  mix-blend-mode: multiply;
  width: 100%;
  height: 60px;
}

.ListingHorizontalCon2Image {
  object-fit: cover;
  mix-blend-mode: multiply;
  width: 120px;
  height: 100%;
}

.ListingVirticalCon2Image {
  object-fit: cover;
  mix-blend-mode: multiply;
  width: 100%;
  height: 100px;
}

.ListingMiniVeriticalCon3 {
  background: var(--Item-backg-secondary, #e8e8e8);
  box-sizing: border-box;
  flex-direction: column;
  flex: 1 0 0;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.ListingMiniVeriticalCon3B {
  background: var(--Item-backg-secondary, #e8e8e8);
  box-sizing: border-box;
  border-radius: 6px;
  flex-direction: column;
  flex: 1 0 0;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  min-width: 100px;
  max-width: 100px;
  display: flex;
  overflow: hidden;
}

.ListingMiniVeriticalCon4 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  display: flex;
}

.ListingMiniVeriticalCon5 {
  background: var(--Item-backg-primary, #460e00);
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  padding: 2px 6px;
  display: flex;
}

.ListingMiniVeriticalCon6 {
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  padding: 0 6px;
  display: flex;
}

.ListingMiniVeriticalCon7 {
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
}

.ListingMiniVeriticalCon8 {
  flex-direction: column;
  flex: 1 0 0;
  align-items: flex-start;
  gap: 2px;
  display: flex;
}

.ListingMiniVeriticalCon9 {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.ListingMiniVeriticalCon10 {
  background: var(--Item-true-main, #ff3c00);
  border-radius: 0 3px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0 2px;
  display: flex;
}

.ListingMiniVeriticalCon11 {
  align-self: stretch;
  align-items: flex-end;
  gap: 3px;
  padding: 1px 3px;
  display: flex;
}

.ListingMiniVeriticalCon12 {
  align-items: center;
  gap: 2px;
  display: flex;
}

.BusinessesPackOfPacksDisplayCon1 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
  padding: 0;
  display: flex;
}

.BusinessesPackDisplayCon1 {
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  width: 100%;
  min-height: 230px;
  display: flex;
}

.BusinessesPackDisplayCon2 {
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  padding: 0 12px;
  display: flex;
}

.BusinessesPackDisplayTitle {
  color: var(--app-charcoal-dark, #2b2b2b);
  letter-spacing: .2px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.BusinessesPackDisplayVerTodo {
  color: var(--app-green, #3a7d44);
  letter-spacing: .12px;
  text-decoration-skip-ink: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-thickness: auto;
}

.BusinessesPackDisplayCon3 {
  scroll-snap-type: x mandatory;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  padding: 6px;
  scroll-padding-left: 20px;
  scroll-padding-right: 20px;
  display: flex;
  overflow: scroll hidden;
}

.BusinessesPackDisplayCon3 > * {
  scroll-snap-align: start;
}

.BusinessInSearchCon1 {
  width: 100%;
  min-width: 314px;
  max-width: 500px;
  box-shadow: var(--app-shadow-businesses-search);
  border-radius: 5px;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
  position: relative;
  overflow: hidden;
}

.BusinessInSearchCon1BackgroundImg {
  object-fit: cover;
  mix-blend-mode: multiply;
  z-index: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.BusinessInSearchCon2 {
  flex-direction: column;
  flex: 1 0 0;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  display: flex;
  position: relative;
}

.BusinessInSearchCon3 {
  z-index: 0;
  background: linear-gradient(#000000b3 0%, #0000 74.5%), linear-gradient(179deg, #0000 1.15%, #000000b3 99.49%);
  flex-direction: column;
  flex: 1 0 0;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.BusinessInSearchCon4 {
  box-sizing: border-box;
  flex: 1 0 0;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  padding: 6px;
  display: flex;
}

.BusinessInSearchCon5 {
  flex-direction: column;
  flex: 1 0 0;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
}

.BusinessInSearchCon6 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
}

.BusinessInSearchBusinessName {
  color: var(--Header-primary, #ff3c00);
  text-shadow: 1px 1.5px 4px #000000e6;
  letter-spacing: .16px;
  align-self: stretch;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.BusinessInSearchBusinessShortDescription {
  color: var(--Header-primary, #ff3c00);
  text-shadow: 1px 1.5px 4px #000000e6;
  letter-spacing: .24px;
  align-self: stretch;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.BusinessInSearchCon7 {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 0 6px;
  display: flex;
}

.BusinessInSearchCon8 {
  object-fit: cover;
  background: #d3d3d3 50% / cover no-repeat;
  border: 2px solid #888;
  border-radius: 25%;
  width: 60px;
  height: 60px;
}

.BusinessInSearchCon9 {
  background: #fff;
  border-bottom: 1px solid #000;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  border-radius: 0 0 5px 5px;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  padding: 0;
  display: flex;
  position: relative;
  box-shadow: inset 0 0 4px 2px #00000080;
}

.BusinessInSearchCon9B {
  background: linear-gradient(141deg, #f4c542 50.91%, #3a7d44 50.91%);
}

.BusinessInSearchCon10 {
  scroll-snap-type: x mandatory;
  align-self: stretch;
  align-items: center;
  gap: 6px;
  padding: 6px;
  scroll-padding-left: 12px;
  display: flex;
  position: relative;
  overflow: scroll hidden;
}

.BusinessInSearchCon10 > * {
  scroll-snap-align: start;
}

.ListingPackDisplayCon1 {
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  width: 100%;
  min-height: 160px;
  padding: 0;
  display: flex;
}

.ListingPackDisplayCon2 {
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
}

.ListingPackDisplayCon3 {
  scroll-snap-type: x mandatory;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  padding: 6px;
  scroll-padding-left: 16px;
  display: flex;
  overflow-x: scroll;
}

.ListingPackDisplayCon3 > * {
  scroll-snap-align: start;
}

.RatingStarCon1 {
  justify-content: center;
  align-items: flex-end;
  gap: 6px;
  display: inline-flex;
}

.RatingStarCon2 {
  background: var(--Mercado-MAI-pro, linear-gradient(0deg, #f80 0%, #e2ff04 100%));
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  gap: 3px;
  padding: 2px 3px 2px 2px;
  display: flex;
  box-shadow: 0 4px 4px #00000040, inset 0 0 6px #00000040;
}

.RatingStarRatedAtNumber {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.RatingStarCon3 {
  align-items: center;
  gap: 2px;
  display: flex;
}

.RatingStarReviewText {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.NoListingsMiniCon1 {
  border: 1px solid #3a7d44;
  border-radius: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-width: 100px;
  max-width: 140px;
  height: 151px;
  display: inline-flex;
  overflow: hidden;
}

.NoListingsMiniCon2 {
  background: #3a7d44;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  gap: 6px;
  height: 18px;
  padding-top: 3px;
  padding-bottom: 3px;
  display: flex;
}

.NoListingsMiniTitle {
  color: #fff;
  word-wrap: break-word;
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
}

.NoListingsMiniCon3 {
  background: var(--app-beige);
  flex: 1 1 0;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding-left: 6px;
  padding-right: 6px;
  display: inline-flex;
}

.NoListingsMiniMessage {
  text-align: center;
  color: #3a7d44;
  word-wrap: break-word;
  flex: 1 1 0;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
}

.ListingDisplayDateCon {
  flex: 1 0 0;
  align-items: center;
  gap: 10px;
  padding: 2px 0;
  display: flex;
}

.ListingDisplayDateTextMini {
  color: #6d5300;
  flex: 1 0 0;
  font-family: Inter;
  font-size: 6px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.ListingDisplayDateText {
  color: #6d5300;
  flex: 1 0 0;
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.ListingDisplayViewsText {
  color: #6d5300;
  flex: 1 0 0;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ListingVeriticalBigCon1 {
  border-radius: 6px;
  flex-direction: column;
  flex: 250px;
  align-items: center;
  max-width: 500px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 2px 4px 6px #00000040;
}

.ListingVirticalBigCon2Image {
  object-fit: cover;
  mix-blend-mode: multiply;
  width: 100%;
  height: 180px;
}

.ListingVeriticalBigCon3 {
  background: #fff;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  display: flex;
}

.ListingVeriticalBigCon4 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  display: flex;
}

.ListingVeriticalBigCon5 {
  background: var(--app-green, #3a7d44);
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  padding: 4px 6px;
  display: flex;
}

.ListingVeriticalBigTitle {
  color: var(--app-white, #fff);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ListingVeriticalBigCon6 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  padding: 0 6px;
  display: flex;
}

.ListingVeriticalBigCon7 {
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
}

.ListingVeriticalBigCon8 {
  flex-direction: column;
  flex: 1 0 0;
  align-items: flex-start;
  gap: 12px;
  display: flex;
}

.ListingVeriticalBigCon9 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  display: flex;
}

.ListingVeriticalBigCon10 {
  background: var(--liniar-for-logo, linear-gradient(0deg, #3a7d44 0%, #69e37b 100%));
  border-radius: 0 3px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 2px 4px;
  display: flex;
}

.ListingVeriticalBigOnDiscount {
  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ListingVeriticalBigPrice {
  color: var(--app-orange, #ff7043);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.ListingVeriticalBigCondition {
  color: var(--app-orange, #ff7043);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ListingVeriticalBigCon11 {
  flex-direction: column;
  align-items: flex-end;
  gap: 2px;
  display: flex;
}

.ListingVeriticalBigPriceBeforeText, .ListingVeriticalBigPriceBeforePrice {
  color: var(--app-green, #3a7d44);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ListingVeriticalBigDescription {
  color: var(--app-gray-medium, #6d6d6d);
  letter-spacing: .12px;
  align-self: stretch;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ListingMiniVeriticalBigCon11 {
  align-self: stretch;
  align-items: flex-end;
  gap: 3px;
  padding: 3px 6px;
  display: flex;
}

.ListingDisplayBigDateCon {
  border-radius: 50px;
  flex: 1 0 0;
  align-items: center;
  gap: 10px;
  padding: 2px 0;
  display: flex;
  box-shadow: 0 2px 4px 1px #ffffff80;
}

.ListingDisplayBigDateText, .ListingDisplayBigViewsText {
  color: var(--app-gray-medium, #6d6d6d);
  flex: 1 0 0;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ListingMiniVeriticalBigCon12 {
  align-items: center;
  gap: 2px;
  display: flex;
}

.ListingVeriticalBigCon12 {
  align-self: stretch;
  align-items: flex-end;
  gap: 3px;
  padding: 3px 6px;
  display: flex;
}
/*# sourceMappingURL=index.7cfc7b90.css.map */
